<template>
<v-container class="pa-1 ma-3" >

  <v-tabs v-model="tab1">
    <v-tab>
      <div>日报</div>
    </v-tab>
    <v-tab>
      <div>周报</div>
    </v-tab>
    <v-tab>
      <div>月报</div>
    </v-tab>
  </v-tabs>

  <v-tabs-items v-model="tab1">
    <v-tab-item>

      <v-container class="ma-3 pa-3">
        <v-row>
          <v-menu ref="dPickerMenu" offset-y :close-on-content-click="false">
            <template v-slot:activator="{attrs, on}">
              <v-btn v-bind="attrs" v-on="on" color="primary" class="mr-5">
                日期选择
              </v-btn>
            </template>
            <v-card>
              <v-date-picker v-model="dPicker" type="month" locale="zh" @change="onDPickerChange"></v-date-picker>
            </v-card>
          </v-menu>
          <v-btn color="primary" @click="onSetCurMonth" :disabled="dPicker==thisMonth">
            本月
          </v-btn>
        </v-row>

        <v-row class="ma-0 pa-4 d-flex justify-center">
          <span class="text-h5">{{dPicker.replace('-','年')+'月'}}日报统计</span>
        </v-row>

        <v-row class="ma-0 pa-0">
          <v-icon color="green">mdi-check</v-icon>
          <span>按时提交</span>
          <v-icon color="purple" class="pl-5">mdi-check</v-icon>
          <span>n小时/n天逾期多久提交</span>
        </v-row>

        <v-row>
          <div style="overflow-x:scroll; ">
            <v-data-table height="500" dense :headers="headersDay" :items="dataDay" item-key="name" class="elevation-1">
							<template v-for="days in 31" v-slot:[`item.${days}`]="{ item }">
								<v-icon v-if="item[days]" color="green" @click="handle_displayRightSummaryEdit(item[days])">mdi-check</v-icon>
							</template>
							<template v-slot:[`item.owner`]="{ item }">
								<div class="d-flex justify-start">
									<UserAvatar :name="item.owner.slice(0,1)" width='30px' height='30px' :userId="item.ownerId" />
									<span class="pa-4 pl-3">{{item.owner}}</span>
								</div>
							</template>
						</v-data-table>
          </div>
        </v-row>

        <v-row>
        </v-row>

      </v-container>
    </v-tab-item>






    <v-tab-item>
      <v-container class="ma-3 pa-3">
        <v-row>
          <v-menu ref="wPickerMenu" offset-y :close-on-content-click="false">
            <template v-slot:activator="{attrs, on}">
              <v-btn v-bind="attrs" v-on="on" color="primary" class="mr-5">
                日期选择
              </v-btn>
            </template>
            <v-card>
              <v-date-picker v-model="wPicker" type="month" locale="zh" @change="onWPickerChange"></v-date-picker>
            </v-card>
          </v-menu>
          <v-btn color="primary" @click="onSetCurMonth1" :disabled="wPicker==thisMonth">
            本月
          </v-btn>
        </v-row>

        <v-row class="ma-0 pa-4 d-flex justify-center">
          <span class="text-h5">{{wPicker.replace('-','年')+'月'}}周报统计</span>
        </v-row>

        <v-row class="ma-0 pa-0">
          <v-icon color="green">mdi-check</v-icon>
          <span>按时提交</span>
          <v-icon color="purple" class="pl-5">mdi-check</v-icon>
          <span>n小时/n天逾期多久提交</span>
        </v-row>

        <v-row>
          <div style="overflow-x:scroll; width:100%; ">
            <v-data-table dense height="500" :headers="headersWeek" :items="dataWeek" item-key="name" class="elevation-1">
							<template v-for="weeks in 5" v-slot:[`item.${weeks}`]="{ item }">
								<v-icon v-if="item[weeks]" color="green" @click="handle_displayRightSummaryEditW(item[weeks])">mdi-check</v-icon>
							</template>
							<template v-slot:[`item.owner`]="{ item }">
								<div class="d-flex justify-start">
									<UserAvatar :name="item.owner.slice(0,1)" width='30px' height='30px' :userId="item.ownerId" />
									<span class="pa-4 pl-3">{{item.owner}}</span>
								</div>
							</template>
						</v-data-table>
          </div>
        </v-row>

        <v-row>
        </v-row>

      </v-container>
    </v-tab-item>






    <v-tab-item>
      <v-container class="ma-3 pa-3">
        <v-row>
          <v-menu ref="mPickerMenu" offset-y :close-on-content-click="false">
            <template v-slot:activator="{attrs, on}">
              <v-btn v-bind="attrs" v-on="on" color="primary" class="mr-5">
                日期选择
              </v-btn>
            </template>
            <v-card>
              <v-date-picker v-model="mPicker" type="month" locale="zh" @change="onMPickerChange"></v-date-picker>
            </v-card>
          </v-menu>
        </v-row>

        <v-row class="ma-0 pa-4 d-flex justify-center">
          <span class="text-h5">{{mPicker.replace(/(.*)-(.*)/, "$1")+'年'}}月报统计</span>
        </v-row>

        <v-row class="ma-0 pa-0">
          <v-icon color="green">mdi-check</v-icon>
          <span>按时提交</span>
          <v-icon color="purple" class="pl-5">mdi-check</v-icon>
          <span>n小时/n天逾期多久提交</span>
        </v-row>

        <v-row>
          <div style="overflow-x:scroll; width:100%; ">
            <v-data-table dense height="500" :headers="headersMonth" :items="dataMonth" item-key="name" class="elevation-1">
							<template v-for="months in 12" v-slot:[`item.${months}`]="{ item }">
								<v-icon v-if="item[months]" color="green" @click="handle_displayRightSummaryEditM(item[months])">mdi-check</v-icon>
							</template>
							<template v-slot:[`item.owner`]="{ item }">
								<div class="d-flex justify-start">
									<UserAvatar :name="item.owner.slice(0,1)" width='30px' height='30px' :userId="item.ownerId" />
									<span class="pa-4 pl-3">{{item.owner}}</span>
								</div>
							</template>
						</v-data-table>
          </div>
        </v-row>

        <v-row>
        </v-row>

      </v-container>
    </v-tab-item>

  </v-tabs-items>

  <v-row>
		<RightSummaryEdit ref="summaryEdit" />
  </v-row>

</v-container>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import {
  mapGetters,
  mapActions
} from 'vuex';
import {
  constant
} from '@/constants/constant.js';
import moment from 'moment';

export default {
  props: ['selObj'],
  components: {
    DateRangePicker,
    UserAvatar: () => import('@/components/common/UserAvatar.vue'),
		RightSummaryEdit :() => import('@/components/summary/rightSummaryEdit/RightSummaryEdit.vue'),
  },
  data() {
    return {
      tab1: 0,
			thisMonth: new Date().toISOString().substr(0, 7),
      dPicker: new Date().toISOString().substr(0, 7),
      wPicker: new Date().toISOString().substr(0, 7),
      mPicker: new Date().toISOString().substr(0, 7),
      owners: [{
        userId: 0,
        userName: "",
        checked: true,
      }],
      thisTable: [],
      headers: [{
          text: '人员',
          value: 'owner',
          width: '160',
          align: 'start'
        },
        {
          text: '总数',
          value: 'allCnt',
          width: '60',
          align: 'center'
        },
        {
          text: '按时',
          value: 'onTimeCnt',
          align: 'center',
          width: '60',
        },
        {
          text: '总分',
          value: 'Scores',
          align: 'center',
          width: '60',
        },
      ],
      chinaWeeks: ['日', '一', '二', '三', '四', '五', '六', ],
      headersDay: [],
      headersWeek: [],
      headersMonth: [],
			dataDay:[],
			dataWeek:[],
			dataMonth:[],
    };
  },

  watch: {
    selObj: {
      handler: function (val) {
        if (val.tab == 1 && val.type == 1) {
          this.owners = [{
            userId: this.authUser.id,
            userName: this.authUser.employeeName,
            checked: true,
          }];
        }
        if (val.tab == 1 && val.type == 2) {
          let xiashu_users = this.users.filter(user => {
            return user.user_superior == this.authUser.id;
          }).map(user => {
            let newUser = {}
            newUser.userId = user.id;
            newUser.userName = user.employeeName;
            newUser.checked = true;
            return newUser;
          });
          this.owners = xiashu_users;
          this.owners.unshift({
            userId: this.authUser.id,
            userName: this.authUser.employeeName,
            checked: true,
          })
        }

        if (val.tab == 2 && (val.type + "").includes(".")) {
          let xiashu_users = this.users.filter(user => {
            return (user.user_dp_serial + "." + user.id + ".").includes(val.type);
          }).map(user => {
            let newUser = {}
            newUser.userId = user.id;
            newUser.userName = user.employeeName;
            newUser.checked = true;
            return newUser;
          });
          this.owners = xiashu_users;
        }
				this.setDReport();
				this.setWReport();
				this.setMReport();
      },
      deep: true
    }
  },
  mounted() {
    this.owners = [{
      userId: this.authUser.id,
      userName: this.authUser.employeeName,
      checked: true,
    }];

    this.setDReport();
		this.setWReport();
		this.setMReport();
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("user", ["users"]),
  },
  methods: {
		...mapActions('report', ['getReportCall']),
		handle_displayRightSummaryEdit(prop) {
      this.$refs.summaryEdit.displayRightSummary({value:prop, type:"daily"});
    },
		handle_displayRightSummaryEditW(prop) {
      this.$refs.summaryEdit.displayRightSummary({value:prop, type:"weekly"});
    },
		handle_displayRightSummaryEditM(prop) {
      this.$refs.summaryEdit.displayRightSummary({value:prop, type:"monthly"});
    },

    onSetCurMonth() {
      this.dPicker = new Date().toISOString().substr(0, 7);
      this.setDReport();
    },
		onSetCurMonth1() {
			this.wPicker = new Date().toISOString().substr(0, 7);
      this.setWReport();
    },
		onSetCurMonth2() {
			this.mPicker = new Date().toISOString().substr(0, 7);
      this.setMReport();
    },

    onDPickerChange() {
      this.$refs.dPickerMenu.save();
      this.setDReport();
    },
		onWPickerChange() {
      this.$refs.wPickerMenu.save();
      this.setWReport();
    },
		onMPickerChange() {
      this.$refs.mPickerMenu.save();
      this.setMReport();
    },
		setMReport() {
      let curMonth = new Date(this.mPicker.substr(0, 4), this.mPicker.substr(5, 2), 0);
      this.headersMonth = [...this.headers];
      for (let i = 1; i <= 12; i++) {
        this.headersMonth.push({
          text: i + '月',
          align: 'center',
          sortable: true,
          value:  i,
        });
      }
			
			let dataObj={};

			for (const owner of this.owners){
				dataObj[owner.userId] = {};
				dataObj[owner.userId]['owner'] = owner.userName;
				dataObj[owner.userId]['ownerId'] = owner.userId;
				dataObj[owner.userId]['allCnt'] = 0;
				dataObj[owner.userId]['onTimeCnt'] = 0;
				dataObj[owner.userId]['Scores'] = 0;
			}

			this.getReportCall({
				rp_type: 2,
				rp_date: this.mPicker.substr(0, 4) + "-%",
			}).then(res=>{
				res.filter(rp=>{
					return this.owners.filter(user=>{
						return user.userId==rp.rp_creator;
					}).length;
				})
				.map(rp=>{
					dataObj[rp.rp_creator][(rp.rp_date.replace(/(.*)-(.*)/, "$2")*1)] =rp;
					dataObj[rp.rp_creator]['allCnt'] ++;
					dataObj[rp.rp_creator]['onTimeCnt'] ++;
				});
				this.dataMonth = [];
				for (const [key, value] of Object.entries(dataObj)) {
					this.dataMonth.push(value);
				}
			})
    },

		setWReport() {
      let curMonth = new Date(this.wPicker.substr(0, 4), this.wPicker.substr(5, 2), 0);
      this.headersWeek = [...this.headers];
      for (let i = 1; i <= 5; i++) {
        let sDate = new Date(this.wPicker.substr(0, 4), this.wPicker.substr(5, 2) + 1, i);
        this.headersWeek.push({
          text: '第'+i + '周',
          align: 'center',
          sortable: true,
          value:  i,
        });
      }
			
			let dataObj={};

			for (const owner of this.owners){
				dataObj[owner.userId] = {};
				dataObj[owner.userId]['owner'] = owner.userName;
				dataObj[owner.userId]['ownerId'] = owner.userId;
				dataObj[owner.userId]['allCnt'] = 0;
				dataObj[owner.userId]['onTimeCnt'] = 0;
				dataObj[owner.userId]['Scores'] = 0;
			}

			this.getReportCall({
				rp_type: 1,
				rp_date: this.wPicker.substr(0, 4) + "-" + (this.wPicker.substr(5, 2)*1) + "/%",
			}).then(res=>{

				res.filter(rp=>{
					return this.owners.filter(user=>{
						return user.userId==rp.rp_creator;
					}).length;
				})
				.map(rp=>{
					dataObj[rp.rp_creator][(rp.rp_date.replace(/(.*)-(.*)\/(.*)/, "$3")*1)] =rp;
					dataObj[rp.rp_creator]['allCnt'] ++;
					dataObj[rp.rp_creator]['onTimeCnt'] ++;

				});

				this.dataWeek = [];

				for (const [key, value] of Object.entries(dataObj)) {
					this.dataWeek.push(value);
				}
			})
    },



    setDReport() {
      let curMonth = new Date(this.dPicker.substr(0, 4), this.dPicker.substr(5, 2), 0);

      this.headersDay = [...this.headers];
      for (let i = 1; i <= curMonth.getDate(); i++) {
        let sDate = new Date(this.dPicker.substr(0, 4), this.dPicker.substr(5, 2) + 1, i);
        this.headersDay.push({
          text: i + ' ' + this.chinaWeeks[sDate.getDay()],
          align: 'center',
          sortable: true,
          value:  i,
        });
      }
			
			let dataObj={};

			for (const owner of this.owners){
				dataObj[owner.userId] = {};
				dataObj[owner.userId]['owner'] = owner.userName;
				dataObj[owner.userId]['ownerId'] = owner.userId;
				dataObj[owner.userId]['allCnt'] = 0;
				dataObj[owner.userId]['onTimeCnt'] = 0;
				dataObj[owner.userId]['Scores'] = 0;
			}

			this.getReportCall({
				rp_type: 0,
				rp_date: this.dPicker.substr(0, 4) + "-" + (this.dPicker.substr(5, 2)*1) + "-%",
			}).then(res=>{

				res.filter(rp=>{
					return this.owners.filter(user=>{
						return user.userId==rp.rp_creator;
					}).length;
				})
				.map(rp=>{
					dataObj[rp.rp_creator][(rp.rp_date.replace(/(.*)-(.*)-(.*)/, "$3")*1)] =rp;
					dataObj[rp.rp_creator]['allCnt'] ++;
					dataObj[rp.rp_creator]['onTimeCnt'] ++;

				});

				this.dataDay = [];

				for (const [key, value] of Object.entries(dataObj)) {
					this.dataDay.push(value);
				}
			})
    },
    getExpiredDays(stopDate) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      let s1 = new Date(stopDate);
      let s2 = new Date();
      '过期天'
      let diff = Math.floor((s1 - s2) / _MS_PER_DAY);
      return diff > 0 ? '过期' + diff + '天' : null;
    },
  }
}
</script>

<style scoped>
.vue-daterange-picker {
  /* width: 100%; */
}
</style>
